export const CalculationService = {
    sumPollutionOnCoordinates,
    averagePollutionOnCoordinates,
    countPollutionOnCoordinates,
    maxPollutionOnCoordinates,
    minPollutionOnCoordinates,
    calculateAverage
};

//short alg
//map all values
//copy one of obj
//delete coordonates
//make sum and put in copy object
//add object{} to new valueArray 

//send newValueArray on return
function sumPollutionOnCoordinates(uniquePollutionArray) {
    let sum = 0;
    let sumPollutionObjectList = [];

    uniquePollutionArray.map((innerArray, i) => {
        for (let index = 2; index < innerArray.length; index++) {
            sum += innerArray[index].Value;

            if(index === innerArray.length - 1){
                let cloneObject = Object.assign([], innerArray[index]);
                cloneObject.Value = sum;
                sum = 0;
                sumPollutionObjectList.push(cloneObject);
            }
        }
    });
    return sumPollutionObjectList;
}

function averagePollutionOnCoordinates(uniquePollutionArray){
    let sum = 0;
    let avgPollutionObjectList = [];

    uniquePollutionArray.map((innerArray, i) => {
        for (let index = 2; index < innerArray.length; index++) {
            sum += innerArray[index].Value;

            if(index === innerArray.length - 1){
                let cloneObject = Object.assign([], innerArray[index]);
                cloneObject.Value = (sum / (innerArray.length - 2));
                sum = 0;
                avgPollutionObjectList.push(cloneObject);
            }
        }
    });
    return avgPollutionObjectList;
}

function calculateAverage(uniquePollutionArray){
    let sum = 0;
        for (let index = 0; index < uniquePollutionArray.length; index++) {
            sum += parseFloat(uniquePollutionArray[index].Value);
        }
    return (sum / uniquePollutionArray.length).toFixed(3);
}

function countPollutionOnCoordinates(uniquePollutionArray){
    let count = 0;
    let countPollutionObjectList = [];

    uniquePollutionArray.map((innerArray, i) => {
        for (let index = 2; index < innerArray.length; index++) {
            count++;
            if(index === innerArray.length - 1){
                let cloneObject = Object.assign([], innerArray[index]);
                cloneObject.Value = count;
                count = 0;
                countPollutionObjectList.push(cloneObject);
            }
        }
    });
    return countPollutionObjectList;
}

function maxPollutionOnCoordinates(uniquePollutionArray){
    let maxPollutionObjectList = [];
    let values = [];

    uniquePollutionArray.map((innerArray, i) => {
        for (let index = 2; index < innerArray.length; index++) {
            values.push(innerArray[index].Value);          
            if(index === innerArray.length - 1){
                let cloneObject = Object.assign([], innerArray[index]);
                cloneObject.Value = Math.max(...values);
                values = [];
                maxPollutionObjectList.push(cloneObject);
            }
        }
    });
    return maxPollutionObjectList;
}

function minPollutionOnCoordinates(uniquePollutionArray){
    let minPollutionObjectList = [];
    let values = [];

    uniquePollutionArray.map((innerArray, i) => {
        for (let index = 2; index < innerArray.length; index++) {
            values.push(innerArray[index].Value);          
            if(index === innerArray.length - 1){
                let cloneObject = Object.assign([], innerArray[index]);
                cloneObject.Value = Math.min(...values);
                values = [];
                minPollutionObjectList.push(cloneObject);
            }
        }
    });

    return minPollutionObjectList;
}
