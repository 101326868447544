import DeckGL from 'deck.gl';
import { StaticMap } from 'react-map-gl';
import { PathLayer, ColumnLayer } from '@deck.gl/layers';
import React, { Component } from 'react';
import { ElasticService } from '../Service/ElasticService';
import { CalculationService } from '../Service/CalculationService';
import { DirectionService } from '../Service/DirectionService';
import { AVERAGE_CO2_2021 } from '../Utils/Contants';

const INITIAL_VIEW_STATE = {
    longitude: 25.6102746,
    latitude: 45.6523093,
    zoom: 13.5,
    pitch: 70,
    bearing: -60
};

export default class GeoJsonLayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layersState: [],
            sensor: "pm10",
            co2Value: 0,
            gl: {},
            points: "Start",
            points: [],
            googleMapsRoutes: [],
            routeAveragePollution: 0,
            routePollutionDataList: [],
            uniqueDataPoints: []
        }
    }

    componentDidMount = async event => {
        var interval = this.convertTimeSeriesToTimeStamp();
        let uniqueDataPoints = await this.getSensorValuesFromElastic(interval, "pm10");
        this.setState({uniqueDataPoints: uniqueDataPoints});
    }

    getSensorValuesFromElastic = async (interval, sensor) => {
        let valueArray = [];
        if (sensor !== "Sensor") {
            await ElasticService.GetElasticData(interval, sensor).then((response) => {
                response.response.hits.hits.map((currElement, index) => {
                    let coordnatesArray = { COORDINATES: [currElement._source.LocationLong, currElement._source.LocationLat] };
                    Object.assign(currElement._source, coordnatesArray);
                    delete currElement._source.LocationLat;
                    delete currElement._source.LocationLong;
                    valueArray.push(currElement._source);
                });
            })
        }

        let listOfCoordinates = [];
        valueArray.map((currElement) => {
            listOfCoordinates.push(currElement.COORDINATES);
        });

        const uniquePoints = Array.from(
            new Map(listOfCoordinates.map((p) => [p.join(), p])).values()
        )

        uniquePoints.map((element, i) => {
            valueArray.map((currElement) => {
                if (element[0] === currElement.COORDINATES[0] && element[1] === currElement.COORDINATES[1]) {
                    uniquePoints[i].push(
                        currElement
                    );
                }
            })
        });
        return uniquePoints;
    }


    convertTimeSeriesToTimeStamp() {
        var dateTs1 = new Date();
        var dateTs2 = new Date();

        return {
            //ts1: dateTs1.setDate(dateTs1.getDate() - 1),
            ts1: dateTs1.setTime(dateTs1.getTime() - (2*60*60*1000)),
            ts2: dateTs2.getTime()
        }


    }

    // DeckGL and mapbox will both draw into this WebGL context
    onWebGLInitialized = (gl) => {
        this.setState({ gl });
    }

    // Add deck layer to mapbox
    onMapLoad = () => {
        const map = this._map;
        const deck = this._deck;
        //map.addLayer(new MapboxLayer({ id: 'my-hexagonlayer', deck }), 'waterway-label');
        map.loadImage(
            'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
            function (error, image) {
                if (error) throw error;
                map.addImage('custom-marker', image);
                // Add a GeoJSON source with 2 points
                map.addSource('points', {
                    'type': 'geojson',

                    'data': {
                        'type': 'FeatureCollection',
                        'features': []
                    }
                });

                // Add a symbol layer
                map.addLayer({
                    'id': 'points',
                    'type': 'symbol',
                    'source': 'points',
                    'layout': {
                        'icon-image': 'custom-marker',
                        // get the title name from the source's "title" property
                        'text-field': ['get', 'title'],
                        'text-font': [
                            'Open Sans Semibold',
                            'Arial Unicode MS Bold'
                        ],
                        'text-offset': [0, 1.25],
                        'text-anchor': 'top'
                    }
                });
            }
        );
    }

    //https://docs.mapbox.com/mapbox-gl-js/example/geojson-markers/
    //https://jsfiddle.net/api/post/library/pure/
    //https://deck.gl/docs/api-reference/layers/path-layer

    click = async (e, map) => {
        if (this.state.points.length === 1) {
            this.setState({ layersState: [] })
        }
        this.state.points.push(
            {
                "coordinates": [e.coordinate[0], e.coordinate[1]]
            }
        );

        if (this.state.points.length === 2) {
            map.getSource('points').setData({
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "properties": { "title": "Finish" },
                    "geometry": {
                        "type": "Point",
                        "coordinates": [e.coordinate[0], e.coordinate[1]]
                    }
                }]
            });
        } else {
            map.getSource('points').setData({
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "properties": { "title": "Start" },
                    "geometry": {
                        "type": "Point",
                        "coordinates": [e.coordinate[0], e.coordinate[1]]
                    }
                }]
            });
        }

        if (this.state.points.length === 2) {
            let dataPath = await DirectionService.GetGoogleShortestPath(this.state.points[0].coordinates[1], this.state.points[0].coordinates[0],
                this.state.points[1].coordinates[1], this.state.points[1].coordinates[0], 'walking');
            let coordinates = []
            dataPath.response.routes.map((route) => {
                route.legs[0].steps.map((step) => {
                    coordinates.push([step.start_location.lng, step.start_location.lat]);
                });

                this.state.googleMapsRoutes.push(coordinates);
            });

            let dataMaps = [];
            this.state.googleMapsRoutes.map((route) => {
                dataMaps.push({
                    "path": route,
                    "sensor": "",
                    "avgValue": 0
                })
            })

            dataMaps[0].avgValue = this.getPollutionDataForSpecifiedRoute(this.state.googleMapsRoutes, this.state.uniqueDataPoints)
            dataMaps[0].sensor = this.state.sensor;

            this.setState({
                layersState: [
                    new PathLayer({
                        id: 'PathLayer',
                        data: dataMaps,
                        getPath: d => d.path,
                        getWidth: d => 4,
                        getColor: d => this.state.sensor === "co2" ?
                        this.colorRangeConvertor(((d.avgValue * 100) / AVERAGE_CO2_2021) * 1.2 / 360, 1, .5)
                        : this.colorRangeConvertor(d.avgValue * 1.2 / 360, 1, .5),
                        widthMinPixels: 5,
                        pickable: true,
                    })
                ]
            }, () => {
            });

            
            this.setState({ status: "Start" });
            this.setState({ points: [] });
            this.setState({ googleMapsRoutes: [] })
        }
    }

    getPollutionDataForSpecifiedRoute(googleMapsRoutes, uniquePollutionPoints){
        let pollutionData = [];
        googleMapsRoutes[0].map((route) => {
            uniquePollutionPoints.map((sensorPosition) => {
                if(this.distance(route[1], route[0], sensorPosition[1], sensorPosition[0], "K") <= 1){
                    pollutionData.push(sensorPosition);
                }
            })
        })

        const uniquePoints = Array.from(
            new Map(pollutionData.map((p) => [p.join(), p])).values()
        )

        this.setState({routePollutionDataList: uniquePoints})
        
        let averageResult = CalculationService.averagePollutionOnCoordinates(uniquePoints);
        let avgValue = CalculationService.calculateAverage(averageResult)
        this.setState({routeAveragePollution: avgValue});
        return avgValue;
    }

    distance(lat1, lon1, lat2, lon2, unit) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1/180;
            var radlat2 = Math.PI * lat2/180;
            var theta = lon1-lon2;
            var radtheta = Math.PI * theta/180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180/Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit=="K") { dist = dist * 1.609344 }
            if (unit=="N") { dist = dist * 0.8684 }
            return dist;
        }
    }

    handleClickShowAlert() {
        this.setState({
            showingAlert: !this.state.showingAlert
        });
    }

    getTooltip({ object }) {
        if (!object) {
            return null;
        }
        return `\
          Valoarea medie a masuratorilor 
          pe o perioada de 2h din momentul curent
          Valoare: ${object.avgValue} PPM
          Senzor: ${object.sensor}
          `;
    }

    handleChange = async event => {
        event.preventDefault();
        this.setState({ sensor: event.target.value });
        var interval = this.convertTimeSeriesToTimeStamp();
        let uniqueDataPoints = await this.getSensorValuesFromElastic(interval, event.target.value);
        this.setState({uniqueDataPoints: uniqueDataPoints});
        this.setState({ layersState: [] })
    };

    colorRangeConvertor(h, s, l) {
        var r, g, b;
        if (s == 0) {
            r = g = b = l;
        } else {
            function hue2rgb(p, q, t) {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            }

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.floor(g * 255), Math.floor(r * 255), Math.floor(b * 255)];
    }

    render() {
        const { gl, layersState } = this.state;
        return (
            <div className="dashboard">
                <DeckGL
                    ref={ref => {
                        // save a reference to the Deck instance
                        this._deck = ref && ref.deck;
                    }}
                    layers={layersState}
                    initialViewState={INITIAL_VIEW_STATE}
                    controller={true}
                    getTooltip={this.getTooltip}
                    onClick={(e) => {
                        this.click(e, this._map)
                    }
                    }
                    onWebGLInitialized={this.onWebGLInitialized}
                >
                    <StaticMap
                        ref={ref => {
                            // save a reference to the mapboxgl.Map instance
                            this._map = ref && ref.getMap();
                        }}
                        mapStyle="mapbox://styles/mapbox/streets-v11"
                        mapboxApiAccessToken="pk.eyJ1IjoiZ2VvcmdlY29zbWluIiwiYSI6ImNrcWU2ZGp3YzBla2UycHF2dHc4dnVhcmQifQ.SMedd2xbmxPzrOcPCO52_Q"
                        onLoad={this.onMapLoad}
                    />


                </DeckGL>
                <div>
                <nav className="navbar navbar-expand-lg navbar navbar-dark bg-dark">
                    <a className="navbar-brand" style={{ marginLeft: "1%" }}><i className="fa fa-map-marker" style={{ fontSize: "25px", position: "realtive" }}></i></a>
                    <ul className="nav navbar-nav mr-auto mt-2 mt-lg-0">
                        <li className="nav-item active">
                            <a className="nav-link" href="/">Hexagonal Layer<span className="sr-only"></span></a>
                        </li>
                        <li className="nav-item active">
                            <a className="nav-link" href="/HeatMap">HeatMap Layer<span className="sr-only"></span></a>
                        </li>
                        <li className="nav-item active">
                            <a className="nav-link" href="/GeoJsonLayer">GeoJson Layer<span className="sr-only"></span></a>
                        </li>
                        <li className="nav-item dropdown">
                            <div className={`alert alert-success ${this.state.showingAlert ? 'alert-shown' : 'alert-hidden'}`}>
                                Proiect realizat in cadrul facultatii IESC. <br/>
                                Datele senzorilor de poluare sunt preluate de la Waqi si UradMonitor.
                            </div>
                            <a className="nav-link" style={{ marginLeft: "4%" }}><i className="fa fa-question" style={{ fontSize: "25px", position: "relative", cursor: "pointer" }} onClick={this.handleClickShowAlert.bind(this)}></i></a>
                        </li>
                    </ul>

                    <form className="form-inline my-2 my-lg-0">

                        
                        <select className="form-control" onChange={this.handleChange}>
                            <option value="pm10">pm10</option>
                            <option value="o3">o3</option>
                            <option value="pm25">pm2.5</option>
                            <option value="pm1">pm1</option>
                            <option value="co2">co2</option>
                            <option value="no2">no2</option>
                            <option value="cho2">cho2</option>
                        </select>
                       
                        
                    </form>
                </nav>
            </div>
            </div >
        );
    }
}