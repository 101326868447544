import React, { Component } from 'react';
import DeckGL from '@deck.gl/react';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { StaticMap } from 'react-map-gl';
import { MapboxLayer } from '@deck.gl/mapbox';
import TimeRangeSlider from '../../src/components/time-slider-component/TimeRangeSlider'

const INITIAL_VIEW_STATE = {
    longitude: 25.6102746,
    latitude: 45.6523093,
    zoom: 12.5,
    pitch: 60,
    bearing: -50
};

export default class HeatMapLayerClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layersState: [],
            sensor: "",
            co2Value: 0,
            gl: {},
        }
    }
    // DeckGL and mapbox will both draw into this WebGL context
    _onWebGLInitialized = (gl) => {
        this.setState({ gl });
    }

    // Add deck layer to mapbox
    _onMapLoad = () => {
        const map = this._map;
        const deck = this._deck;
        map.addLayer(new MapboxLayer({ id: 'my-heatmap-layer', deck }), 'waterway-label');
    }

    getTooltip({ object }) {
        if (!object) {
            return null;
        }
        let lat = 0;
        let lng = 0;
        return `\
          Latitude: ${Number.isFinite(lat) ? object.COORDINATES[0].toFixed(6) : ''}
          Longitude: ${Number.isFinite(lng) ? object.COORDINATES[1].toFixed(6) : ''}
          Value: ${object.Value} ${object.Measurement}
          Sensor: ${object.Sensor}
          Source: ${object.Source}`;
    }

    getPollutionSensor = (pollutionSensor) => {
        this.setState({ sensor: pollutionSensor });

    }
	
	    myUniqueID(){
        return Math.random().toString(36).slice(2);
    }

    SetDataFromTimeRangeSlider = (valueArray) => {
        this.setState({
            layersState: [
                new HeatmapLayer({
                    id: this.myUniqueID(),
                    data: valueArray,
                    pickable: true,
                    getPosition: d => d.COORDINATES,
                    getWeight: d => d.Value,
                    radiusPixels: 35,
                    intensity: 1.5,
                    threshold: 0.0000
                  })
            ]
        })
    }

    render() {
        const { gl, layersState } = this.state;
        const layers = layersState;
        return (
            <div className="dashboard">
                <DeckGL
                    ref={ref => {
                        // save a reference to the Deck instance
                        this._deck = ref && ref.deck;
                    }}
                    layers={layers}
                    initialViewState={INITIAL_VIEW_STATE}
                    controller={true}
                    getTooltip={this.getTooltip}
                    onWebGLInitialized={this._onWebGLInitialized}
                >
                    {
                        gl && (
                            <StaticMap
                                ref={ref => {
                                    // save a reference to the mapboxgl.Map instance
                                    this._map = ref && ref.getMap();
                                }}
                                gl={gl}
                                mapStyle="mapbox://styles/mapbox/streets-v11"
                                mapboxApiAccessToken="pk.eyJ1IjoiZ2VvcmdlY29zbWluIiwiYSI6ImNrcWU2ZGp3YzBla2UycHF2dHc4dnVhcmQifQ.SMedd2xbmxPzrOcPCO52_Q"
                                onLoad={this._onMapLoad}
                                preventStyleDiffing={true}
                            />
                        )}

                </DeckGL>
                <TimeRangeSlider onTimeRangeSlider={this.SetDataFromTimeRangeSlider}
                    getPollutionSensor={this.getPollutionSensor}
                ></TimeRangeSlider>
            </div>
        )
    }
}