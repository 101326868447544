import { ELASTIC_ENPOINTS } from '../Utils/Contants';

export const ElasticService = {
    GetElasticData
};

async function GetElasticData(interval, sensor) {
    let response = await fetch(ELASTIC_ENPOINTS, {
        mode: 'cors',
        method: "POST",
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "size": 10000,
            "query": {
                "bool": {
                    "must": [
                        {
                            "range": {
                                "TimeStamp": {
                                    "gte": interval.ts1,
                                    "lte": interval.ts2
                                }
                            }
                        },
                        {
                            "match": {
                                "Sensor": sensor
                            }
                            
                        }
                    ]
                }
            }

        })
    });
    return await response.json();
}