import React, { Component } from 'react'

class FooterComponent extends Component {
    render() {
        return (
            <div className="footer">
                <p>Harta Poluare Brasov ©{new Date().getFullYear()}</p>
            </div>
        )
    }
}
export default FooterComponent;