import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../nav-bar/NavBar.css";
import {FaInfoCircle} from 'react-icons/fa'
import { toast } from 'react-toastify';

export default class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingAlert: false,
            agreggationValue: ""
        }
        this.infotextBox=this.infotextBox.bind(this)
    }
    infotextBox(){
        toast.info('Proiect realizat in cadrul facultatii IESC. \nDatele senzorilor de poluare sunt preluate de la Waqi si UradMonitor.', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    }

    handleClickShowAlert() {
        this.setState({
            showingAlert: !this.state.showingAlert
        });
    }

    render() {
        return (

            <>
                <div class="topnav">
                    <a className="nav-link" href="/">Hexagonal Layer<span className="sr-only"></span></a>
                    <a className="nav-link" href="/HeatMap">HeatMap Layer<span className="sr-only"></span></a>
                    <a className="nav-link" href="/GeoJsonLayer">Path Layer<span className="sr-only"></span></a>
                    <FaInfoCircle className = "infoCircle" onClick= {this.infotextBox}></FaInfoCircle>
                    <div class="topnav-right">
                        <ul className="lista">
                            <li><div>
                                {
                                    this.props.uniquePollutionArray && this.props.uniquePollutionArray.length > 0
                                        ?
                                        <select value={this.props.agreggationValue} className="form-control" onChange={this.props.handleAggregationMethod}>
                                            <option value="agreggation">Agreggation method</option>
                                            <option value="avg">Average</option>
                                            <option value="count">Count</option>
                                            <option value="sum">Sum</option>
                                            <option value="max">Max</option>
                                            <option value="min">Min</option>
                                        </select>
                                        : null
                                }
                            </div>
                            </li>
                            <li><div>
                                <select value={this.props.timeSeries} className="form-control" onChange={this.props.handleTimeSeriesToState}>
                                    <option value="TimeSeries">TimeSeries</option>
                                    <option value="1d">1 day</option>
                                    <option value="7d">7 day</option>
                                    <option value="30d">30 day</option>
                                    <option value="6M">6 months</option>
                                    <option value="1Y">1 year</option>
                                </select>
                            </div>
                            </li>
                            <li><div>
                                <DatePicker
                                    className="form-control"
                                    style={{ marginBottom: "10px" }}
                                    showPopperArrow={false}
                                    selected={this.props.currentDate}
                                    onChange={(newDate) => this.props.getDate(newDate)}
                                />
                            </div>
                            </li>
                            <li><div>
                                <select className="form-control" onChange={this.props.handleChange}>
                                    <option defaultValue={this.state.agreggationValue}>Sensor</option>
                                    <option value="pm10">pm10</option>
                                    <option value="o3">o3</option>
                                    <option value="pm25">pm2.5</option>
                                    <option value="pm1">pm1</option>
                                    <option value="co2">co2</option>
                                    <option value="no2">no2</option>
                                    <option value="cho2">cho2</option>
                                </select>
                            </div>
                            </li>
                            <li className="divButton">
                                <div>
                                    <button className="buton success" onClick={this.props.sendFiltersToElasticCall}>Send</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
            //     <div className="container">
            //         <nav className="navbar navbar-dark bg-dark">
            //             <div className="row">
            //                 <div>
            //                     <a className="navbar-brand"><i className="fa fa-map-marker" style={{ fontSize: "25px", position: "realtive" }}></i></a>
            //                     <ul className="nav navbar-nav">
            //                         <li className="nav-item active">
            //                             <a className="nav-link" href="/">Hexagonal Layer<span className="sr-only"></span></a>
            //                         </li>
            //                         <li className="nav-item active">
            //                             <a className="nav-link" href="/HeatMap">HeatMap Layer<span className="sr-only"></span></a>
            //                         </li>
            //                         <li className="nav-item active">
            //                             <a className="nav-link" href="/GeoJsonLayer">Path Layer<span className="sr-only"></span></a>
            //                         </li>
            //                         <li className="nav-item dropdown">
            //                             <div className={`alert alert-success ${this.state.showingAlert ? 'alert-shown' : 'alert-hidden'}`}>
            //                                 Proiect realizat in cadrul facultatii IESC. <br />
            //                                 Datele senzorilor de poluare sunt preluate de la Waqi si UradMonitor.
            //                             </div>
            //                             <a className="nav-link" style={{ marginLeft: "4%" }}><i className="fa fa-question" style={{ fontSize: "25px", position: "relative", cursor: "pointer" }} onClick={this.handleClickShowAlert.bind(this)}></i></a>
            //                         </li>
            //                     </ul>
            //                 </div>
            //                 <div>
            //                     <form className="form-inline">
            //                         {
            //                             this.props.uniquePollutionArray && this.props.uniquePollutionArray.length > 0
            //                                 ?
            //                                 <select value={this.props.agreggationValue} className="form-control" onChange={this.props.handleAggregationMethod} style={{ marginRight: "7px" }}>
            //                                     <option value="agreggation">Agreggation method</option>
            //                                     <option value="avg">Average</option>
            //                                     <option value="count">Count</option>
            //                                     <option value="sum">Sum</option>
            //                                     <option value="max">Max</option>
            //                                     <option value="min">Min</option>
            //                                 </select>
            //                                 : null
            //                         }
            //                         <select value={this.props.timeSeries} className="form-control" onChange={this.props.handleTimeSeriesToState} style={{ marginRight: "7px" }}>
            //                             <option value="TimeSeries">TimeSeries</option>
            //                             <option value="1d">1 day</option>
            //                             <option value="7d">7 day</option>
            //                             <option value="30d">30 day</option>
            //                             <option value="6M">6 months</option>
            //                             <option value="1Y">1 year</option>
            //                         </select>
            //                         <DatePicker
            //                             className="form-control"
            //                             showPopperArrow={false}
            //                             selected={this.props.currentDate}
            //                             onChange={(newDate) => this.props.getDate(newDate)}
            //                         />
            //                         <select className="form-control" onChange={this.props.handleChange}>
            //                             <option defaultValue={this.state.agreggationValue}>Sensor</option>
            //                             <option value="pm10">pm10</option>
            //                             <option value="o3">o3</option>
            //                             <option value="pm25">pm2.5</option>
            //                             <option value="pm1">pm1</option>
            //                             <option value="co2">co2</option>
            //                             <option value="no2">no2</option>
            //                             <option value="cho2">cho2</option>
            //                         </select>

            //                         <button className="btn btn-outline-success" style={{ marginLeft: "7px" }} onClick={this.props.sendFiltersToElasticCall}>Send</button>
            //                     </form>
            //                 </div>
            //             </div>
            //         </nav>
            //     </div>
        )
    }
}
