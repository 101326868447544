import { GOOGLE_MAPS_API_URL, GOOGLE_API_KEY, MAPS_API_URL } from '../Utils/Contants';

export const DirectionService = {
    GetGoogleShortestPath
};

async function GetGoogleShortestPath(latStart, lonStart, latFinish, lonFinish, mode) {
     let response = await fetch(GOOGLE_MAPS_API_URL, {
        mode: 'cors',
        method: "POST",
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "URL": MAPS_API_URL + `?origin=${latStart}, ${lonStart}&destination=${latFinish}, ${lonFinish}&mode=${mode}&key=${GOOGLE_API_KEY}`})
    })
        return response.json();
    
}