import React from 'react';
import DeckGL, { ColumnLayer } from 'deck.gl';
import { StaticMap } from 'react-map-gl';
import { MapboxLayer } from '@deck.gl/mapbox';
import { Component } from 'react';
import TimeRangeSlider from '../components/time-slider-component/TimeRangeSlider'
import { AVERAGE_CO2_2021 } from '../Utils/Contants';
import ReactMapGL from 'react-map-gl';
import mapboxgl from "mapbox-gl";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const INITIAL_VIEW_STATE = {
    longitude: 25.6102746,
    latitude: 45.6523093,
    zoom: 12.5,
    pitch: 60,
    bearing: -50
};

export default class HexagonalLayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layersState: [],
            sensor: "",
            co2Value: 0,
            gl: {},
        }
    }

    myUniqueID(){
        return Math.random().toString(36).slice(2);
    }
	
	
	  
    SetDataFromTimeRangeSlider = (valueArray) => {
        debugger;
        this.setState({
            layersState: [
                new ColumnLayer({
                    id: this.myUniqueID(),
                    data: valueArray,
                    pickable: true,
                    filled: true,
                    radius: 40,
                    diskResolution: 5,
                    elevationRange: [0, 100],
                    elevationScale: 35,
                    getFillColor: d => this.state.sensor === "co2" ?
                        this.colorRangeConvertor(((d.Value * 100) / AVERAGE_CO2_2021) * 1.2 / 360, 1, .5)
                        : this.colorRangeConvertor(d.Value * 1.2 / 360, 1, .5),
                    getElevation: d => d.Value,
                    upperPercentile: 100,
                    getPosition: d => d.COORDINATES,
                })
            ]
        }, () => {
        });
        
    }

    getPollutionSensor = (pollutionSensor) => {
        this.setState({ sensor: pollutionSensor });

    }

    getTooltip({ object }) {
        if (!object) {
            return null;
        }
        let lat = 0;
        let lng = 0;
        return `\
          Latitude: ${Number.isFinite(lat) ? object.COORDINATES[0].toFixed(6) : ''}
          Longitude: ${Number.isFinite(lng) ? object.COORDINATES[1].toFixed(6) : ''}
          Value: ${object.Value} ${object.Measurement}
          Sensor: ${object.Sensor}
          Source: ${object.Source}`;
    }

    colorRangeConvertor(h, s, l) {
        var r, g, b;
        if (s == 0) {
            r = g = b = l;
        } else {
            function hue2rgb(p, q, t) {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            }

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.floor(g * 255), Math.floor(r * 255), Math.floor(b * 255)];
    }

    // DeckGL and mapbox will both draw into this WebGL context
    onWebGLInitialized = (gl) => {
        this.setState({ gl });
    }

    // Add deck layer to mapbox
    onMapLoad = () => {
        const map = this._map;
        const deck = this._deck;
        map.addLayer(new MapboxLayer({ id: 'my-hexagonlayer', deck }), 'waterway-label');
        map.resize();
    }

    render() {
        const { gl, layersState } = this.state;
        return (
            <div className = "dashboard"> 
                <DeckGL 
                    ref={ref => {
                        // save a reference to the Deck instance
                        this._deck = ref && ref.deck;
                    }}
                    layers={layersState}
                    initialViewState={INITIAL_VIEW_STATE}
                    controller={true}
                    getTooltip={this.getTooltip}
                    onWebGLInitialized={this.onWebGLInitialized}
                >

                    {
                        gl && (
                            <StaticMap
                                ref={ref => {
                                    // save a reference to the mapboxgl.Map instance
                                    this._map = ref && ref.getMap();
                                }}
                                gl={gl}
                                mapStyle="mapbox://styles/mapbox/streets-v11"
                                mapboxApiAccessToken="pk.eyJ1IjoiZ2VvcmdlY29zbWluIiwiYSI6ImNrcWU2ZGp3YzBla2UycHF2dHc4dnVhcmQifQ.SMedd2xbmxPzrOcPCO52_Q"
                                onLoad={this.onMapLoad}
                                preventStyleDiffing={true}
                            />

                        )}
                </DeckGL>

                <TimeRangeSlider
                    onTimeRangeSlider={this.SetDataFromTimeRangeSlider}
                    getPollutionSensor={this.getPollutionSensor}
                >

                </TimeRangeSlider>
            </div>
        );
    }
}