import React, { Component } from 'react';
import AuthenticationService from '../api/AuthenticationService'
import HexagonalLayer from '../Maps/HexagonalLayer'
class DashboardComponent extends Component {

    render() {
        return (
            <div className="dashboard">
                <HexagonalLayer></HexagonalLayer>
              </div>
        )
    }
}
export default DashboardComponent;