import { Component } from 'react';
import TimeRange from "react-timeline-range-slider";
import { selectedInterval, disabledIntervals, timelineInterval } from "./DatesSouce";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { ElasticService } from '../../Service/ElasticService';
import { CalculationService } from '../../Service/CalculationService';
import NavBar from "../nav-bar/NavBar";
import '../../App.css';
import 'react-toastify/dist/ReactToastify.css';


export default class TimeRangeSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            selectedInterval,
            currentDate: new Date(),
            sensor: "",
            timeSeries: "",
            agreggationValue: "",
            uniquePollutionArray: [],
            firstAttempt: true
        }
    }

    converIntervalToTimeStamp() {
        var strDate = 'm/d/Y'
            .replace('Y', this.state.currentDate.getFullYear())
            .replace('m', this.state.currentDate.getMonth() + 1)
            .replace('d', this.state.currentDate.getDate());

        var firstTsInterval = Date.parse(strDate + " " + this.state.selectedInterval[0].toTimeString());
        var secondTsInterval = Date.parse(strDate + " " + this.state.selectedInterval[1].toTimeString());

        return {
            ts1: firstTsInterval,
            ts2: secondTsInterval
        }
    }

    convertTimeSeriesToTimeStamp(timeStandard) {
        var strDate = 'm/d/Y'
            .replace('Y', this.state.currentDate.getFullYear())
            .replace('m', this.state.currentDate.getMonth() + 1)
            .replace('d', this.state.currentDate.getDate());
        var currentChosenDate = this.state.selectedInterval

        //var currentTimeStamp = Date.parse(strDate + " " + this.state.selectedInterval[1].toTimeString());
        var secondTsInterval = Date.parse(strDate + " " + this.state.selectedInterval[1].toTimeString());
        var date = new Date(strDate);
        switch (timeStandard) {
            case "TimeSeries":
                return;
            case "1d":
                return {
                    ts1: date.setDate(date.getDate() - 1),
                    ts2: secondTsInterval
                }
            case "7d":
                return {
                    ts1: date.setDate(date.getDate() - 7),
                    ts2: secondTsInterval
                }
            case "30d":
                return {
                    ts1: date.setMonth(date.getMonth() - 1),
                    ts2: secondTsInterval
                }
            case "6M":
                return {
                    ts1: date.setMonth(date.getMonth() - 6),
                    ts2: secondTsInterval
                }
            case "1Y":
                return {
                    ts1: date.setFullYear(date.getFullYear() - 1),
                    ts2: secondTsInterval
                }
        }
    }

    getSensorValuesFromElastic = async (interval) => {
        let valueArray = [];
        if (this.state.sensor !== "") {
            await ElasticService.GetElasticData(interval, this.state.sensor).then((response) => {
                response.response.hits.hits.map((currElement, index) => {
                    let coordnatesArray = { COORDINATES: [currElement._source.LocationLong, currElement._source.LocationLat] };
                    Object.assign(currElement._source, coordnatesArray);
                    delete currElement._source.LocationLat;
                    delete currElement._source.LocationLong;
                    valueArray.push(currElement._source);
                });
            })
        }

        let listOfCoordinates = [];
        valueArray.map((currElement) => {
            listOfCoordinates.push(currElement.COORDINATES);
        });

        const uniquePoints = Array.from(
            new Map(listOfCoordinates.map((p) => [p.join(), p])).values()
        )

        uniquePoints.map((element, i) => {
            valueArray.map((currElement) => {
                if (element[0] === currElement.COORDINATES[0] && element[1] === currElement.COORDINATES[1]) {
                    uniquePoints[i].push(
                        currElement
                    );
                }
            })
        });

        this.setState({ uniquePollutionArray: uniquePoints });
        this.setState({ agreggationValue: "agreggation" });

        return valueArray;
    }

    handleAggregationMethod = e => {
        e.preventDefault();
        e.stopPropagation();
        let valueArray = [];
        this.setState({ agreggationValue: e.target.value });
        if (e.target.value !== "agreggation") {
            switch (e.target.value) {

                case "sum":
                    valueArray = CalculationService.sumPollutionOnCoordinates(this.state.uniquePollutionArray);
                    break;
                case "avg":
                    valueArray = CalculationService.averagePollutionOnCoordinates(this.state.uniquePollutionArray);
                    break;
                case "count":
                    valueArray = CalculationService.countPollutionOnCoordinates(this.state.uniquePollutionArray);
                    break;
                case "max":
                    valueArray = CalculationService.maxPollutionOnCoordinates(this.state.uniquePollutionArray);
                    break;
                case "min":
                    valueArray = CalculationService.minPollutionOnCoordinates(this.state.uniquePollutionArray);
                    break;
            }
        }

        this.props.onTimeRangeSlider(valueArray);
        if (this.state.firstAttempt) {
            this.setState({ firstAttempt: false })
        }
    }

    handleTimeSeriesToState = async event => {
        event.preventDefault();

        this.setState({ timeSeries: event.target.value });
        //var interval = this.convertTimeSeriesToTimeStamp(event.target.value);
        //let valueArray = this.getSensorValuesFromElastic(interval);
        //this.props.onTimeRangeSlider(valueArray);
    }

    getStateFormNavBar = (data) => {
        this.setState({ currentDate: data.currentDate, sensor: data.sensor });
    }

    sendFiltersToElasticCall = event => {
        event.preventDefault();
        var interval;
        if (this.state.timeSeries !== "" && this.state.timeSeries !== "TimeSeries") {
            interval = this.convertTimeSeriesToTimeStamp(this.state.timeSeries);
        } else {
            var interval = this.converIntervalToTimeStamp();
        }

        let valueArray = this.getSensorValuesFromElastic(interval);
        //this.setState({ timeSeries: "TimeSeries" });
        this.props.onTimeRangeSlider(valueArray);
        this.props.getPollutionSensor(this.state.sensor);
    }

    errorHandler = ({ error }) => this.setState({ error });

    onChangeCallback = (selectedInterval) => {
        this.setState({ selectedInterval });
    };

    getDate = (date) => {
        this.setState({ currentDate: date });

    }

    handleChange = event => {
        this.setState({ sensor: event.target.value });
    };

    render() {
        const { selectedInterval, error } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <NavBar
                            handleChange={this.handleChange}
                            getDate={this.getDate}
                            sendFiltersToElasticCall={this.sendFiltersToElasticCall}
                            handleTimeSeriesToState={this.handleTimeSeriesToState}
                            handleAggregationMethod={this.handleAggregationMethod}
                            currentDate={this.state.currentDate}
                            uniquePollutionArray={this.state.uniquePollutionArray}
                            agreggationValue={this.state.agreggationValue}
                            timeSeries={this.state.timeSeries}
                        >
                        </NavBar>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" style={{

                            position: "fixed",
                            bottom: "0",
                            left: "0",
                            right: "0",
                            opacity: 1,
                            marginLeft: "5%"
                        }}>
                            <TimeRange
                                error={error}
                                ticksNumber={36}
                                selectedInterval={selectedInterval}
                                timelineInterval={timelineInterval}
                                onUpdateCallback={this.errorHandler}
                                onChangeCallback={this.onChangeCallback}
                                disabledIntervals={disabledIntervals}

                                style={{
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}